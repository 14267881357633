import videojs from 'video.js';
import styled from 'styled-components';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-eme';
import 'videojs-max-quality-selector';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo, useRef, useState, useContext } from 'react';
import './MkvPlayer.css';
import CastingView from './CastingView';
import './MkvPlayerOverlay';
import ViewIcon from '../assets/view.svg';
import { AppContext } from '../AppContext';

const Button = videojs.getComponent('Button');
const licenseUri = 'https://license-global.pallycon.com/ri/licenseManager.do';
function base64DecodeUint8Array(input) {
  var raw = window.atob(input);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) array[i] = raw.charCodeAt(i);

  return array;
}

function base64EncodeUint8Array(input) {
  var keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  var output = '';
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output +=
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4);
  }
  return output;
}

const MkvPlayer = (props) => {
  const {
    src,
    handleOnPlay,
    onPlayerError,
    keepaliveFunc,
    keepaliveError,
    gaKeepaliveFunc,
    contentId,
    token,
    isLive,
    title,
    viewCount,
  } = props;
  const { session } = useContext(AppContext);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const overlayRef = useRef(null);
  const [firstPlayed, setFirstPlayed] = useState(true);
  const [keepaliveTimer, setKeepaliveTimer] = useState(null);
  const [gaTimer, setGaTimer] = useState(null);
  const [casting, setCasting] = useState(false);

  const type = useMemo(() => {
    if (src.endsWith('.m3u8')) {
      return 'stream';
    } else {
      return 'mp4';
    }
  }, [src]);

  useEffect(() => {
    console.log('overlay:', viewCount, overlayRef.current);
    if (overlayRef.current && isLive) {
      const curr = overlayRef.current.get();
      if (curr.length > 0) {
        console.log('overlay length:', curr);
        overlayRef.current.remove(curr[0]);
      }
      console.log('overlay add:');
      overlayRef.current.add({
        content: `<div><img src=${ViewIcon} /> ${viewCount ?? 0}</div>`,
        start: 0,
        end: 'endeed',
      });
    }
  }, [viewCount]);

  const initPlayer = () => {
    try {
      if (videoRef.current) {
        if (!playerRef.current) {
          const videoElement = document.createElement('video-js');
          videoElement.classList.add('vjs-big-play-centered');
          videoRef.current.appendChild(videoElement);

          const player = videojs(
            videoElement,
            {
              aspectRatio: '16:9',
              autoplay: isLive ? true : false,
              controls: true,
              playsinline: true,
              // experimentalSvgIcons: true,
              controlBar: {
                remainingTimeDisplay: false,
                progressControl: {
                  seekBar: isLive ? false : true,
                },
              },
              html5: {
                nativeTextTracks: false,
              },
            },
            () => {
              videojs.log('player is ready');

              if (window.cjs && window.cjs.available) {
                window.cjs.on('error', (e) => {
                  console.log('Cast error', e);
                });
                const Button = videojs.getComponent('Button');
                const btn = new Button(player, {
                  clickHandler: function () {
                    console.log('current:', player.currentTime());
                    if (window.cjs && window.cjs.available) {
                      if (token && contentId) {
                        window.cjs.cast(
                          src,
                          {
                            title: title,
                          },
                          {
                            licenseUrl:
                              `https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=` +
                              token,
                            contentId: contentId,
                          }
                        );
                      } else {
                        window.cjs.cast(
                          src,
                          {
                            title: title,
                          },
                          {
                            cookies: [
                              {
                                name: 'CloudFront-Key-Pair-Id',
                                value: session['CloudFront-Key-Pair-Id'],
                              },
                              {
                                name: 'CloudFront-Policy',
                                value: session['CloudFront-Policy'],
                              },
                              {
                                name: 'CloudFront-Signature',
                                value: session['CloudFront-Signature'],
                              },
                            ],
                          }
                        );
                      }
                    }
                  },
                });
                btn.addClass('vjs-casting-button');

                player.getChild('controlBar')?.addChild(btn, {}, 10);
              }

              if (window.WebKitPlaybackTargetAvailabilityEvent) {
                const btn = new Button(player, {
                  clickHandler: function () {
                    const mediaEl = player.el().querySelector('video, audio');
                    if (mediaEl && mediaEl.webkitShowPlaybackTargetPicker) {
                      mediaEl.webkitShowPlaybackTargetPicker();
                    } else {
                      console.log('no webkitShowPlaybackTargetPicker');
                    }
                  },
                });
                btn.addClass('vjs-airplay-button');

                player.getChild('controlBar')?.addChild(btn, {}, 10);
              }

              player.maxQualitySelector({
                displayMode: 1,
              });

              if (token) {
                player.eme();
                player.controlBar.progressControl.disable();
                if (src.endsWith('.m3u8')) {
                  player.eme.initLegacyFairplay();
                  player.src({
                    src,
                    type: 'application/x-mpegurl',
                    keySystems: {
                      'com.apple.fps.1_0': {
                        getCertificate: function (_, callback) {
                          videojs.xhr(
                            {
                              url: 'https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=PTSA',
                              method: 'GET',
                            },
                            function (err, _, responseBody) {
                              if (err) {
                                callback(err);
                                return;
                              }
                              try {
                                const parsed = JSON.parse(responseBody);
                                if (parsed.errorCode) {
                                  const error = new Error(
                                    `DRM Error: ${parsed.errorCode} - ${parsed.message}`
                                  );
                                  console.log('====> parsed', parsed);
                                  Sentry.captureException(error, {
                                    data: { parsed },
                                  });
                                  callback(error);
                                }
                              } catch (err) {}

                              callback(
                                null,
                                base64DecodeUint8Array(responseBody)
                              );
                            }
                          );
                        },
                        getContentId: function (_, contentId) {
                          const id = contentId.substring(
                            contentId.indexOf('skd://') + 6
                          );
                          return id;
                        },
                        getLicense: function (_, __, keyMessage, callback) {
                          videojs.xhr(
                            {
                              url: licenseUri,
                              method: 'POST',
                              responseType: 'text',
                              body: 'spc=' + base64EncodeUint8Array(keyMessage),
                              headers: {
                                'Content-type':
                                  'application/x-www-form-urlencoded',
                                'pallycon-customdata-v2': token,
                              },
                            },
                            function (err, _, responseBody) {
                              if (err) {
                                callback(err);
                                return;
                              }
                              try {
                                const parsed = JSON.parse(responseBody);
                                if (parsed.errorCode) {
                                  const error = new Error(
                                    `DRM Error: ${parsed.errorCode} - ${parsed.message}`
                                  );
                                  console.log('====> parsed', parsed);
                                  Sentry.captureException(error, {
                                    data: { parsed },
                                  });
                                  callback(error);
                                }
                              } catch (err) {}

                              callback(
                                null,
                                base64DecodeUint8Array(responseBody)
                              );
                            }
                          );
                        },
                      },
                    },
                  });
                } else if (src.endsWith('.mpd')) {
                  player.src({
                    src,
                    type: 'application/dash+xml',
                    keySystems: {
                      'com.widevine.alpha': {
                        url: 'https://license-global.pallycon.com/ri/licenseManager.do',
                        licenseHeaders: {
                          'pallycon-customdata-v2': token,
                        },
                      },
                      'com.microsoft.playready': {
                        url: 'https://license-global.pallycon.com/ri/licenseManager.do',
                        licenseHeaders: {
                          'pallycon-customdata-v2': token,
                        },
                      },
                    },
                  });
                }
              } else {
                player.src({
                  src,
                  type: type === 'mp4' ? 'video/mp4' : 'application/x-mpegurl',
                  withCredentials: true,
                });
              }

              player.on('play', handleOnVideoPlay);
              player.on('error', function () {
                Sentry.captureException(this.error());
                console.log('vjs error: ', this.error());
              });
            }
          );
          if (isLive) {
            overlayRef.current = player.overlay({
              content: `<div><img src=${ViewIcon} /> ${viewCount ?? 0}</div>`,
              start: 'loadedmetadata',
              end: 'ended',
            });
          }

          playerRef.current = player;
        }
      }
    } catch (err) {
      if (onPlayerError) {
        onPlayerError(err);
      }
      console.log('err:', err);
    }
  };

  const handleOnVideoPlay = () => {
    if (firstPlayed) {
      setFirstPlayed(false);
    }
    if (handleOnPlay) {
      handleOnPlay(firstPlayed);
    }

    if (!keepaliveTimer && keepaliveFunc) {
      setKeepaliveTimer(
        setInterval(() => {
          if (keepaliveFunc) {
            keepaliveFunc();
          }
        }, 300000)
      );
    }

    if (!gaTimer && gaKeepaliveFunc) {
      setGaTimer(
        setInterval(() => {
          if (gaKeepaliveFunc) {
            gaKeepaliveFunc();
          }
        }, 900000)
      );
    }
  };

  const disposeCast = () => {
    if (window.cjs && window.cjs.available) {
      if (window.cjs.connected) {
        window.cjs.disconnect();
      }
    }
  };

  useEffect(() => {
    disposeCast();
    if (window) {
      window.addEventListener('unload', disposeCast);

      if (window.cjs) {
        window.cjs.on('timeupdate', () => {
          if (playerRef.current && window.cjs.time != 0) {
            playerRef.current.currentTime(window.cjs.time);
          }
        });
        window.cjs.on('statechange', () => {
          console.log('statechange', window.cjs.state);
          if (window.cjs.state == 'connected') {
            setCasting(true);
            if (playerRef.current) {
              console.log(
                'start casting, resume ',
                playerRef.current.currentTime()
              );
              playerRef.current.pause();
            }
          } else if (window.cjs.state == 'disconnected') {
            setCasting(false);
            if (playerRef.current) {
              console.log(
                'Paused casting, resume ',
                window.cjs.progress,
                window.cjs.duration,
                window.cjs.time
              );
              playerRef.current.play();
            }
          } else if (window.cjs.state == 'playing') {
            if (playerRef.current) {
              console.log(
                'start playing, resume ',
                playerRef.current.currentTime()
              );
              if (!isLive) {
                window.cjs.seek(playerRef.current.currentTime());
              }
            }
          }
        });
      }
    }

    return () => {
      disposeCast();
      window.removeEventListener('unload', disposeCast);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (keepaliveTimer) {
        clearInterval(keepaliveTimer);
      }
    };
  }, [keepaliveTimer]);

  useEffect(() => {
    return () => {
      if (gaTimer) {
        clearInterval(gaTimer);
      }
    };
  }, [gaTimer]);

  useEffect(() => {
    if (keepaliveError) {
      if (keepaliveTimer) {
        clearInterval(keepaliveTimer);
        setKeepaliveTimer(null);
      }

      if (gaKeepaliveFunc) {
        clearInterval(gaKeepaliveFunc);
        setGaTimer(null);
      }
    }
    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }
    if (overlayRef.current) {
      overlayRef.current = null;
    }
    if (window.cjs && window.cjs.available) {
      if (window.cjs.connected) {
        window.cjs.disconnect();
      }
    }
  }, [keepaliveError]);

  useEffect(() => {
    if(session){
      initPlayer();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      if (overlayRef.current) {
        overlayRef.current = null;
      }
    };
  }, [type, session]);

  // useEffect(() => {
  //   if (casting) {
  //     if (playerRef.current) {
  //       playerRef.current.pause();
  //       window.cjs.seek(playerRef.current.currentTime());
  //     }
  //   }
  // }, [casting]);

  return (
    <Container data-vjs-player>
      {casting && <CastingView streamUrl={src} drmToken={token} />}
      <Container
        ref={videoRef}
        style={{ display: `${casting ? 'none' : 'block'}` }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default MkvPlayer;
