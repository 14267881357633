export default {
  IS_DEV: false,
  API_ENDPOINT: 'https://api.mirrorweare.com',
  CHECKOUT_DOMAIN: 'https://stream.mirrorweare.com',
  CLOUDFRONT: 'https://static.mirrorweare.com',
  S3_DOMAIN: 'https://static.mirrorweare.com.s3.ap-east-1.amazonaws.com',
  GLOBAL_PAYMENT_URL: 'https://secureacceptance.cybersource.com/pay',
  GA4_ID: 'G-72WG8TEYSJ',
  GTM_ID: 'GTM-5C59Z3H',
  APPLE_SIGN_IN_CLIENT_ID: '',
  APPLE_SIGN_IN_REDIRECT_URI: '',
  FB_ID: '507532867592006',
  GOOGLE_ID:
    '575263180409-dmpuav9g5manc5dke9sfcf4padeio740.apps.googleusercontent.com',
  EMAIL_REGEX:
    "^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
  FIRSTNAME_REGEX: "^[a-zA-Z\\s\\.'\\-]{2,30}$",
  LASTNAME_REGEX: "^[a-zA-Z\\s\\.'\\-]{2,30}$",
  PHONE_REGEX: '^\\+852[0-9]{8}$',
  PASSWORD_REGEX: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d@$!%*#?&]{8,30}$',
  CONTACT_US_RECAPTCHA: '6Lce-ZsfAAAAANfPX0_szK5fkIjxkotC36vvLw66',
  RECAPTCHA: '6Lce-ZsfAAAAANfPX0_szK5fkIjxkotC36vvLw66',
  ID_FIRST_FOUR_REGEX: '^[a-zA-Z0-9]{4}$',
  MEMBER_PRICE_PER_YEAR: 440,
  RENEW_PRICE_LIST: [
    { month: 5, price: 260 },
    { month: 6, price: 220 },
    { month: 7, price: 180 },
    { month: 8, price: 140 },
    { month: 9, price: 100 },
    { month: 10, price: 0 },
  ],
  CONTINUE_MIRO_DAY: 60,
  MAX_EXPIRY_DAY: -30,
  CLIENT_ID: 'fe074a41d973dd5b0abc3ebba4a4327c',
  CLIENT_SECRET: '8y/A?D(G+KbPeShVmYq3t6w9z$C&E)H@',
  JOIN_BANNER_RESET_DAY: 1,
  MEMBERSHIP_START_DAY: '2025-01-01',
  MEMBERSHIP_EXPIRY_DAY: '2025-12-31',
  RELEASE: '1.0.5',
  JOIN_2025_END_DAY: '2025-01-15',
  JOIN_2025_URL: 'https://www.mirrorweare.com/2025-miro'
};
